import React, { useEffect, useRef } from "react";
import "../css/Reviews.css";

const Reviews = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          section.querySelectorAll(".review, h1").forEach((element) => {
            const elementRect = element.getBoundingClientRect();
            if (elementRect.top <= window.innerHeight && elementRect.bottom >= 0) {
              element.classList.add("visible");
            }
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // 초기 로드 시에도 체크
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section ref={sectionRef} id="reviews" className="reviews-section">
        <h1>Review</h1>
        <div className="reviews-container">
          <div className="review">
            <h3>클라우드 애플리케이션 아키텍처 과정</h3>
            <p>강사님의 열정적인 강의가 너무 좋았습니다. 내용도 흥미로웠고, 이해가 쉽게 가도록 상세하고 친절히 설명해주셔서 감사합니다.</p>
            <p>삼성SDS 조*아님</p>
            <span>★★★★★</span>
          </div>
          <div className="review">
            <h3>MSA 설계 핵심 과정</h3>
            <p>서비스 규모를 키우고자 한다면 MSA는 필수 아키텍쳐 같습니다. 많은 개발자 동료들이 이 강의를 수강해서 우리 회사에 MSA아키텍쳐 문화가 자리 잡았으면 좋겠습니다.</p>
            <p>KT 이*현님</p>
            <span>★★★★★</span>
          </div>
          <div className="review">
            <h3>Node.js를 활용한 웹서비스 구축 과정</h3>
            <p>이 수업 덕분에 새로운 개발 툴도 알게 되고 새로운 지식도 알게되어 정말 도움이 많이 됐습니다. 차근차근 이해하기 쉽도록 설명해주셔서 감사합니다.</p>
            <p>심플렉스 홍*정님</p>
            <span>★★★★★</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reviews;
