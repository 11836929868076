import React from "react";
import "../../css/layouts/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-info">
          <div>
            <span>
              <strong>상호명</strong> 주식회사 드림플로우
            </span>
            <span>
              <strong>대표자명</strong> 조나래
            </span>
          </div>
          <div>
            <span>
              <strong>사업장 주소</strong> (13302) 경기도 성남시 수정구 제일로 192, 5층 (율산빌딩)
            </span>
          </div>
          <div>
            <span>
              <strong>대표 전화</strong> 031-759-7001
            </span>
            <span>
              <strong>Email</strong> info@dream-flow.com
            </span>
          </div>
          <div>
            <span>
              <strong>사업자 등록번호</strong> 151-81-01816
            </span>
            <span>
              <strong>통신판매업 신고번호</strong> 2022-성남중원-0492
              <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1518101816" target="_blank">[사업자정보확인]</a>
            </span>
          </div>
        </div>
        <div className="footer-legal">
          <p>본 사이트의 컨텐츠는 저작권법의 보호를 받는 바, 무단 전재, 복사, 배포를 금합니다</p>
          <p>Copyright © Dream Flow Co., Ltd. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
