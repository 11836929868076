import React, { useEffect, useRef } from "react";
import "../css/Services.css";
import Clients from "./Clients";

const Services = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          section.querySelectorAll(".service, h1").forEach((element) => {
            const elementRect = element.getBoundingClientRect();
            if (elementRect.top <= window.innerHeight && elementRect.bottom >= 0) {
              element.classList.add("visible");
            }
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // 초기 로드 시에도 체크
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Clients />
      <section ref={sectionRef} id="services" className="section services-section">
        <h1>Our Services</h1>
        <div className="services-container">
          <div className="service">
            <h3>맞춤형 교육 프로그램</h3>
            <ul>
              <li>각 기업의 요구와 목표에 맞춘 맞춤형 교육 설계</li>
              <li>최신 트렌드와 기술을 반영한 커리큘럼</li>
              <li>실무 중심의 실습과 사례 연구</li>
            </ul>
          </div>
          <div className="service">
            <h3>전문 강사진</h3>
            <ul>
              <li>업계 전문가들로 구성된 강사진</li>
              <li>풍부한 실무 경험과 이론적 지식을 겸비한 강사</li>
              <li>지속적인 자기 개발과 최신 정보 업데이트를 통한 교육 제공</li>
            </ul>
          </div>
          <div className="service">
            <h3>고퀄리티 교육 자료와 실습</h3>
            <ul>
              <li>누구나 이해하기 쉬운 고퀄리티의 강의 자료 제공</li>
              <li>실습 중심의 커리큘럼으로 실무 능력 향상</li>
              <li>다양한 실습과 사례 연구를 통해 적극적인 참여 유도</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
