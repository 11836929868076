import React, { useState, useRef, useEffect } from "react";
import emailjs from '@emailjs/browser';

import "../css/ContactUs.css";

const ContactUs = () => {
  const emailForm = useRef();
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    title: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_cpmyr1f', 'template_8xepu1d', emailForm.current, '759JqbdeX9sScEnS4')
    .then(() => {
        alert("성공적으로 문의를 보냈습니다.");
        setFormData({
          from_name: "",
          from_email: "",
          title: "",
          message: "",
        });
    }, (error) => {
        console.log(error.text);
        alert("이메일 오류가 있어요. 다시 시도해주세요.");
    });
  };

  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          section.querySelectorAll(".form-wrap, h1").forEach((element) => {
            const elementRect = element.getBoundingClientRect();
            if (elementRect.top <= window.innerHeight && elementRect.bottom >= 0) {
              element.classList.add("visible");
            }
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // 초기 로드 시에도 체크
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section ref={sectionRef} id="contact" className="email-form-section">
      <h1>Contact Us</h1>
      <div className="form-wrap">
        <form ref={emailForm} onSubmit={handleSubmit} className="email-form">
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="from_name" value={formData.from_name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" name="from_email" value={formData.from_email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Title</label>
            <input type="title" name="title" value={formData.title} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea name="message" value={formData.message} onChange={handleChange} required rows="10" />
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
