import React, { useEffect, useRef, useState } from "react";
import "../../css/layouts/Header.css";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const header = headerRef.current;
      const stickyClass = "header-sticky";

      if (window.scrollY > 0) {
        header.classList.add(stickyClass);
      } else {
        header.classList.remove(stickyClass);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const handleClick = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
    setMenuOpen(false); // 메뉴 항목을 클릭하면 메뉴를 닫음
  };

  return (
    <>
      <header ref={headerRef}>
        <nav>
          <div className="logo">DreamFlow</div>
          <div ref={menuRef} className={`menu ${menuOpen ? 'open' : ''}`}>
            <ul>
              <li>
                <a href="#main" onClick={(event) => handleClick(event, "main")}>
                  Main
                </a>
              </li>
              <li>
                <a href="#services" onClick={(event) => handleClick(event, "services")}>
                  Services
                </a>
              </li>
              <li>
                <a href="#reviews" onClick={(event) => handleClick(event, "reviews")}>
                  Reviews
                </a>
              </li>
              <li>
                <a href="#contact-us" onClick={(event) => handleClick(event, "contact-us")}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            ☰
          </div>
        </nav>
      </header>
      <section id="main" className="main-section">
        <video width="100%" height="100%" autoPlay muted loop>
          <source src={`${process.env.PUBLIC_URL}/videos/main.mp4`} type="video/mp4" />
        </video>
        <div>
          기업 교육이 필요하시다면
          <br />
          <span className="highlight">드림플로우</span>에 잘 오신 겁니다
        </div>
      </section>
    </>
  );
}
