import React, { useEffect, useState } from "react";
import Header from "./components/layouts/Header";
import "./css/App.css";
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";
import Reviews from "./components/Review";
import Footer from "./components/layouts/Footer";

const App = () => {
  return (
    <div className="App">
      <Header />
      <div id="services">
        <Services />
      </div>
      <div id="reviews">
        <Reviews />
      </div>
      <div id="contact-us">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default App;