import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "../css/Clients.css";

export default function Clients() {
  const clients = ["kosta", "cas", "buda", "samsung", "kt", "shinhan"];

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, // autoplay 속도를 줄임
    pauseOnHover: false, // 슬라이드가 호버 시 멈추지 않도록 설정
  };

  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          section.querySelectorAll("h1").forEach((element) => {
            const elementRect = element.getBoundingClientRect();
            if (elementRect.top <= window.innerHeight && elementRect.bottom >= 0) {
              element.classList.add("visible");
            }
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // 초기 로드 시에도 체크
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section ref={sectionRef} id="clients" className="clients-section">
      <h1>Clients</h1>
      <div className="clients-container">
        <Slider {...settings}>
          {clients.map((client, i) => (
            <div key={i} className="client">
              <img src={`${process.env.PUBLIC_URL}/clients/${client}.png`} alt={client} className="client-image" />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
